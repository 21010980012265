<template>
	<div v-loading="pageloading">
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-b7dc93ee-4c84-4045-9d93-ee3f06fe4b35.png">
					<div class="info">
						<div class="tit">客户群活码</div>
						<div class="tip">创建客户群活码并投放，客户扫描活码可直接加入群聊，当群成员满200人时，自动创建群聊。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jump">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/商家管理客户群活码</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210802-062f2573-cf4e-4637-9141-3312d89295eb.png"></img>
				<div class="desc-title">2/客户线上扫描投放的群活码快速进群</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210803-de395fa0-51ac-4901-981e-c5dc58051507.png"></img>
			</div>
		</el-card>
		<el-card shadow="never" v-else>
			<div style="display:flex;flex-direction:row;flex-wrap:wrap;justify-content: space-between;">
				<div style="width:70%;display:flex;flex-direction:row;">
					<div style="font-size:14px;line-height:37px;color: #606266;font-weight: bold;white-space: nowrap;">关键字:</div>
					<el-input v-model="keywords" style="width:250px;margin:0px 10px" placeholder="活码名称"></el-input>
					<div><el-button type="primary" @click="queryList">查询</el-button></div>
					<div><el-button type="primary" style="margin:0px 10px" @click="editCode()">添加活码</el-button></div>
				</div>
				<div>

					<qr-codepop :downloadData="experienceCode" :downFont="'下载群活码'"></qr-codepop>
					<!-- <el-button type="text">
            <svg t="1626336483888" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2003" style="float:left" width="15" height="15">
            <path d="M357.6 960.8H254.8c-86.7 0-157.2-70.5-157.2-157.2V632c0-11 9-20 20-20s20 9 20 20v171.5c0 64.6 52.6 117.2 117.2 117.2h102.9c11 0 20 9 20 20s-9 20.1-20.1 20.1zM769.3 960.8H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c64.6 0 117.2-52.6 117.2-117.2V632c0-11 9-20 20-20s20 9 20 20v171.5c-0.1 86.7-70.6 157.3-157.2 157.3zM906.4 412c-11 0-20-9-20-20V220.5c0-64.6-52.6-117.2-117.2-117.2H666.4c-11 0-20-9-20-20s9-20 20-20h102.9c86.7 0 157.2 70.5 157.2 157.2V392c-0.1 11-9 20-20.1 20zM117.6 412c-11 0-20-9-20-20V220.5c0-86.7 70.5-157.2 157.2-157.2h102.9c11 0 20 9 20 20s-9 20-20 20H254.8c-64.6 0-117.2 52.6-117.2 117.2V392c0 11-9 20-20 20zM957.9 532H66.1c-11 0-20-9-20-20s9-20 20-20h891.8c11 0 20 9 20 20s-9 20-20 20z" p-id="2004"></path></svg>
            
            <div style="float:left;margin-left:5px;color:black" @click="experience">功能体验码</div>
          </el-button> -->
				</div>
			</div>
			<!-- 表格 -->
			<div style="margin-top:10px">
				<el-table :data="tableList" v-loading="tableLoading">
					<el-table-column prop="" label="群活码">
						<template slot-scope="scope">
							<div style="display:flex;flex-direction:row">
								<div
									style="font-size:14px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
									{{scope.row.GroupLiveCodeName}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column prop="" label="操作" width="140px">
						<template slot-scope="scope">
							<el-button type="text" @click="editCode(scope.row)">编辑</el-button>
							<el-button type="text" @click="downloadCode(scope.row)">下载</el-button>
							<el-button type="text" style="color:rgb(245,108,108)" @click="deteleCode(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="text-align:right;margin-top:5px" v-if="total">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-card>

		<!-- <batch-cods :weiCodeVisivble="CodeShow" :weiCodeData="CodeData" @cancel="CodeCancel" :downFont="'下载群活码'" :title='"下载群活码"'></batch-cods> -->


		<qr-code :downloadData="downloadData" :qrCodeIshow="qrCodeIshow" :title="'群活码下载'" :downFont="'下载群活码'"
			@cancel="closeQrCode"></qr-code>
	</div>
</template>

<script>
	import batchCods from '@/components/bathCodes';
	import config from "@/config/index";
	import QrCode from "@/components/bathQrCodes"
	import QrCodepop from "@/components/bathQrCodes2"
	import {
		mapGetters
	} from "vuex"
	import {
		grouplivecodelist,
		grouplivecodedelete,
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0"
	export default {
		components: {
			batchCods,
			QrCode,
			QrCodepop
		},
		computed: {
			...mapGetters(['pcImg'])
		},
		data() {
			return {
				pageloading: false,
				goUrls: config.GO_URL,
				keywords: '',
				tableList: [],
				tableLoading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
				CodeShow: false,
				CodeData: {},
				isauthorization: true, //是否配置企业微信
				downloadData: {},
				qrCodeIshow: false,
				experienceCode: {
					url: '',
					icon: this.pcImg,
					name: '',
				}
			}
		},
		created() {
			this.Isqyweixinsuiteauthinfo()
			//气泡群活码
			// var URL = window.location.href;
			// let h = URL.split(".")[0];
			// let domain = h.split("//")[1];
			// var secondParam = ''
			// if (process.env.NODE_ENV == 'development') {
			// 	secondParam = '1' //推手模式
			// 	// secondParam = 'nyhzp'// secondParam ='dke'
			// 	// secondParam ='diankeyi3'
			// 	// secondParam = '1'//推手模式
			// 	// secondParam = 'zz'//免费版
			// 	// secondParam = 'lxy'//代理模式
			// } else {
			// 	secondParam = domain
			// }
			let secondParam = window.localStorage.getItem('mlmzMallKey')
			let urlheader = ''
			if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
				urlheader = 'https://wap.dkyapi.cn'
			} else {
				urlheader = 'https://wap.dkyapitest.cn'
			}
			this.experienceCode = {
				// url: urlheader + '/custom/#/pages/shopCode/shopCode?gid=' + '1212' + '&mallkey=' + secondParam,
				url: 'http://cdn.dkycn.cn/images/melyshop/20210805034410.png',
				icon: this.pcImg,
				name: '功能体验码',
				link: 'https://jusnn6k8al.feishu.cn/docs/doccnQ07zpjVd2ykMulFsNCblRg'
			}
		},
		methods: {
			closeQrCode(val) {
				// console.log(val)
				this.qrCodeIshow = val
			},
			jump() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},
			//删除活码
			deteleCode(e) {
				// console.log(e)
				this.$confirm(`是否确认删除活码【${e.GroupLiveCodeName}】?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.togrouplivecodedelete(e)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				// console.log(Id)

			},
			async togrouplivecodedelete(e) {
				this.pageloading = true;
				
				try{
					let Id = await e
					let data = {
						Id: Id.GroupLiveCodeId
					}
					let result = await grouplivecodedelete(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.$message.success('操作成功')
						this.getList()
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.pageloading = false
				}
				
			},
			//编辑
			editCode(e) {
				let Id = 0
				if (e) {
					Id = e.GroupLiveCodeId
				}
				// console.log(e)
				let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
				let url =
					this.goUrls + headsUrls + "/Customer/GroupLiveCode/changeLiveCode?Id=" + Id;
				window.open(url);
			},
			//下载群活码
			downloadCode(e) {
				// var URL = window.location.href;
				// let h = URL.split(".")[0];
				// let domain = h.split("//")[1];
				// var secondParam = ''
				// if (process.env.NODE_ENV == 'development') {
				// 	secondParam = '1' //推手模式
				// 	// secondParam = 'nyhzp'// secondParam ='dke'
				// 	// secondParam ='diankeyi3'
				// 	// secondParam = '1'//推手模式
				// 	// secondParam = 'zz'//免费版
				// 	// secondParam = 'lxy'//代理模式
				// } else {
				// 	secondParam = domain
				// }
				let secondParam = window.localStorage.getItem('mlmzMallKey')
				let urlheader = ''
				if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage') {
					urlheader = 'https://wap.dkyapi.cn'
				} else {
					urlheader = 'https://wap.dkyapitest.cn'
				}
				this.qrCodeIshow = true
				this.downloadData = {
					url: urlheader + '/custom/#/pages/groupCode/groupCode?gid=' + e.GroupLiveCodeGuid +
						'&type=1&mallkey=' + secondParam,
					icon: this.pcImg,
					name: e.GroupLiveCodeName,
					link: 'https://jusnn6k8al.feishu.cn/docs/doccnQ07zpjVd2ykMulFsNCblRg'
				}
			},
			CodeCancel(value) {
				this.CodeShow = value;
			},

			//页码 页数
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.getList()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.getList()
			},
			queryList() {
				this.currentPage = 1
				this.getList()
			},
			async getList() {
				this.tableLoading = true;
				
				try{
					let data = {
						Keywords: this.keywords,
						GroupLiveCodeType: 1,
						Skip: (this.currentPage - 1) * this.sizepage,
						Take: this.sizepage
					}
					let result = await grouplivecodelist(data)
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.tableList = result.Result.Results
						this.total = result.Result.Total
					}
				}catch(e){
					//TODO handle the exception
				}finally{
					this.tableLoading = false
				}
				
			},
			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp
						if (this.isauthorization) {
							this.getList()
						}
					}
				} finally {
					this.pageloading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.a {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
